@import 'src/styles/helpers';

@mixin textSlider() {
  @for $i from 1 through 200 {
    &:nth-child(#{$i}) {
      @if ($i == 1) {
        transition-delay: 0ms;
      } @else {
        transition-delay: #{($i - 1) * 50}ms;
      }
    }
  }
}

.fadeUpTextWrap {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  transition: none;
  vertical-align: top;
}

.text {
  position: absolute;
  right: 0;
  z-index: 2;
  width: 100%;
  transition: none;

  &__container {
    overflow: hidden;
    transition: none;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;

    &_centered {
      text-align: center;
    }
  }

  .letter {
    display: inline-block;
    transform: translate3d(0, 200px, 0);
    will-change: transform;
    transition: none;
  }

  &_up {

    .letter {
      transform: translate3d(0, -200px, 0);
      transition: transform 1.15s cubic-bezier(0.6, 0, 0.2, 1);
      @include textSlider();
    }
  }

  &_middle {

    .letter {
      transform: translate3d(0, 0, 0);
      transition: transform 1.15s cubic-bezier(0.6, 0, 0.2, 1);
      @include textSlider();
    }
  }
}
